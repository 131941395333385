import { default as about_45pass9QHMEnGJ5DMeta } from "/codebuild/output/src3588095475/src/src/pages/about-pass.vue?macro=true";
import { default as about_45yumenaviMqYGBJyDc0Meta } from "/codebuild/output/src3588095475/src/src/pages/about-yumenavi.vue?macro=true";
import { default as agreement9rRsq1D9HBMeta } from "/codebuild/output/src3588095475/src/src/pages/agreement.vue?macro=true";
import { default as confirm_45card_45alias9d52SBFbmzMeta } from "/codebuild/output/src3588095475/src/src/pages/card/[id]/confirm-card-alias.vue?macro=true";
import { default as confirmjCGpsthSEoMeta } from "/codebuild/output/src3588095475/src/src/pages/card/[id]/confirm.vue?macro=true";
import { default as edit_45card_45alias97fu2CCZPtMeta } from "/codebuild/output/src3588095475/src/src/pages/card/[id]/edit-card-alias.vue?macro=true";
import { default as editp3IWYcHfpBMeta } from "/codebuild/output/src3588095475/src/src/pages/card/[id]/edit.vue?macro=true";
import { default as indexA0UFIUftSZMeta } from "/codebuild/output/src3588095475/src/src/pages/card/[id]/index.vue?macro=true";
import { default as confirmYRSkf6VwudMeta } from "/codebuild/output/src3588095475/src/src/pages/card/create/confirm.vue?macro=true";
import { default as indexdEbjCs1Dc9Meta } from "/codebuild/output/src3588095475/src/src/pages/card/create/index.vue?macro=true";
import { default as indexmwS07d3YW6Meta } from "/codebuild/output/src3588095475/src/src/pages/card/index.vue?macro=true";
import { default as completeopVD1o9SWRMeta } from "/codebuild/output/src3588095475/src/src/pages/contact-form/complete.vue?macro=true";
import { default as confirmJYktaKHr8cMeta } from "/codebuild/output/src3588095475/src/src/pages/contact-form/confirm.vue?macro=true";
import { default as registeri5rKo23o6rMeta } from "/codebuild/output/src3588095475/src/src/pages/contact-form/register.vue?macro=true";
import { default as contactRm6ieommsnMeta } from "/codebuild/output/src3588095475/src/src/pages/contact.vue?macro=true";
import { default as _91id_93adNwS65yU1Meta } from "/codebuild/output/src3588095475/src/src/pages/coupon/[id].vue?macro=true";
import { default as index9SRFThsXsrMeta } from "/codebuild/output/src3588095475/src/src/pages/coupon/index.vue?macro=true";
import { default as faqx1npKNd1t8Meta } from "/codebuild/output/src3588095475/src/src/pages/faq.vue?macro=true";
import { default as history_45debugcCfpatP8w1Meta } from "/codebuild/output/src3588095475/src/src/pages/history-debug.vue?macro=true";
import { default as indexiAYDJ5O4ceMeta } from "/codebuild/output/src3588095475/src/src/pages/history/index.vue?macro=true";
import { default as _91id_93IFVVE6FUuOMeta } from "/codebuild/output/src3588095475/src/src/pages/history/receipt/[id].vue?macro=true";
import { default as indexJLdkrhRx8oMeta } from "/codebuild/output/src3588095475/src/src/pages/index.vue?macro=true";
import { default as maintenanceQLvDrSdmidMeta } from "/codebuild/output/src3588095475/src/src/pages/maintenance.vue?macro=true";
import { default as complete95xDnkB1mDMeta } from "/codebuild/output/src3588095475/src/src/pages/membership/complete.vue?macro=true";
import { default as confirmgYOj13HcdlMeta } from "/codebuild/output/src3588095475/src/src/pages/membership/confirm.vue?macro=true";
import { default as registervkIkyw7kuJMeta } from "/codebuild/output/src3588095475/src/src/pages/membership/register.vue?macro=true";
import { default as confirm_45codeFpLEOhPdSWMeta } from "/codebuild/output/src3588095475/src/src/pages/membership/yumenavi/confirm-code.vue?macro=true";
import { default as confirmQm874ufLKYMeta } from "/codebuild/output/src3588095475/src/src/pages/membership/yumenavi/confirm.vue?macro=true";
import { default as register0kS4hUn4huMeta } from "/codebuild/output/src3588095475/src/src/pages/membership/yumenavi/register.vue?macro=true";
import { default as menu3OdcxCmC4xMeta } from "/codebuild/output/src3588095475/src/src/pages/menu.vue?macro=true";
import { default as indexs9U97uifbFMeta } from "/codebuild/output/src3588095475/src/src/pages/mypage/index.vue?macro=true";
import { default as index2ZcpQfu08OMeta } from "/codebuild/output/src3588095475/src/src/pages/mypage/integration/index.vue?macro=true";
import { default as samplebWsqSPhKXpMeta } from "/codebuild/output/src3588095475/src/src/pages/mypage/integration/sample.vue?macro=true";
import { default as completea4JRgnEXl5Meta } from "/codebuild/output/src3588095475/src/src/pages/mypage/membership/email-edit/complete.vue?macro=true";
import { default as confirm_45codeMra1O0Umt4Meta } from "/codebuild/output/src3588095475/src/src/pages/mypage/membership/email-edit/confirm-code.vue?macro=true";
import { default as indexTYiyQEbINhMeta } from "/codebuild/output/src3588095475/src/src/pages/mypage/membership/email-edit/index.vue?macro=true";
import { default as indexAcfZCUaSQ2Meta } from "/codebuild/output/src3588095475/src/src/pages/mypage/membership/index.vue?macro=true";
import { default as completeAaVpuQv1AFMeta } from "/codebuild/output/src3588095475/src/src/pages/mypage/membership/others-edit/complete.vue?macro=true";
import { default as confirmi0ViDiw9V7Meta } from "/codebuild/output/src3588095475/src/src/pages/mypage/membership/others-edit/confirm.vue?macro=true";
import { default as indexbrjQP3VGOHMeta } from "/codebuild/output/src3588095475/src/src/pages/mypage/membership/others-edit/index.vue?macro=true";
import { default as update3V5t87PphmMeta } from "/codebuild/output/src3588095475/src/src/pages/mypage/membership/others-edit/update.vue?macro=true";
import { default as confirmLfc0ERSDHeMeta } from "/codebuild/output/src3588095475/src/src/pages/mypage/membership/others-edit/yumenavi/confirm.vue?macro=true";
import { default as indexauljaZXkgiMeta } from "/codebuild/output/src3588095475/src/src/pages/mypage/membership/others-edit/yumenavi/index.vue?macro=true";
import { default as updatezPitTtx7lBMeta } from "/codebuild/output/src3588095475/src/src/pages/mypage/membership/others-edit/yumenavi/update.vue?macro=true";
import { default as completeWuniTSURJuMeta } from "/codebuild/output/src3588095475/src/src/pages/mypage/membership/password-edit/complete.vue?macro=true";
import { default as updatevSqVP1gNtCMeta } from "/codebuild/output/src3588095475/src/src/pages/mypage/membership/password-edit/update.vue?macro=true";
import { default as indexecT7kZQ6GeMeta } from "/codebuild/output/src3588095475/src/src/pages/mypage/pass/index.vue?macro=true";
import { default as _91id_938bOSREruzyMeta } from "/codebuild/output/src3588095475/src/src/pages/mypage/pass/receipt/[id].vue?macro=true";
import { default as completenLcIQRHGSYMeta } from "/codebuild/output/src3588095475/src/src/pages/mypage/sign-out/complete.vue?macro=true";
import { default as confirm6PhLtn3y01Meta } from "/codebuild/output/src3588095475/src/src/pages/mypage/sign-out/confirm.vue?macro=true";
import { default as completeciYSKtHqQAMeta } from "/codebuild/output/src3588095475/src/src/pages/mypage/unsubscribe/complete.vue?macro=true";
import { default as confirmaZUSu1XYzFMeta } from "/codebuild/output/src3588095475/src/src/pages/mypage/unsubscribe/confirm.vue?macro=true";
import { default as consent0XveizfUtPMeta } from "/codebuild/output/src3588095475/src/src/pages/mypage/unsubscribe/consent.vue?macro=true";
import { default as _91id_938JvYq4lCKAMeta } from "/codebuild/output/src3588095475/src/src/pages/notice/[id].vue?macro=true";
import { default as indexrWR3KM5e2aMeta } from "/codebuild/output/src3588095475/src/src/pages/notice/index.vue?macro=true";
import { default as _91id_93cNnXeCaP2MMeta } from "/codebuild/output/src3588095475/src/src/pages/parking/[id].vue?macro=true";
import { default as pass_45agreementfm7wuhMHukMeta } from "/codebuild/output/src3588095475/src/src/pages/pass-agreement.vue?macro=true";
import { default as completezgfWSxx9ClMeta } from "/codebuild/output/src3588095475/src/src/pages/reset-password/complete.vue?macro=true";
import { default as confirm_45codeWOO5rMUWNiMeta } from "/codebuild/output/src3588095475/src/src/pages/reset-password/confirm-code.vue?macro=true";
import { default as new_45passwordIOqhod0wqKMeta } from "/codebuild/output/src3588095475/src/src/pages/reset-password/new-password.vue?macro=true";
import { default as send_45emailVO40EMhWzvMeta } from "/codebuild/output/src3588095475/src/src/pages/reset-password/send-email.vue?macro=true";
import { default as confirm_45codeEtBWUaRSGoMeta } from "/codebuild/output/src3588095475/src/src/pages/sign-in/confirm-code.vue?macro=true";
import { default as index91Z9AbJVgdMeta } from "/codebuild/output/src3588095475/src/src/pages/sign-in/index.vue?macro=true";
import { default as confirm_45codeJV8M8CjbaEMeta } from "/codebuild/output/src3588095475/src/src/pages/sign-up/confirm-code.vue?macro=true";
import { default as indexdex1APwl2qMeta } from "/codebuild/output/src3588095475/src/src/pages/sign-up/index.vue?macro=true";
import { default as tutorial3MaB34cE2UMeta } from "/codebuild/output/src3588095475/src/src/pages/tutorial.vue?macro=true";
export default [
  {
    name: "about-pass",
    path: "/about-pass",
    component: () => import("/codebuild/output/src3588095475/src/src/pages/about-pass.vue")
  },
  {
    name: "about-yumenavi",
    path: "/about-yumenavi",
    component: () => import("/codebuild/output/src3588095475/src/src/pages/about-yumenavi.vue")
  },
  {
    name: "agreement",
    path: "/agreement",
    component: () => import("/codebuild/output/src3588095475/src/src/pages/agreement.vue")
  },
  {
    name: "card-id-confirm-card-alias",
    path: "/card/:id()/confirm-card-alias",
    meta: confirm_45card_45alias9d52SBFbmzMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/card/[id]/confirm-card-alias.vue")
  },
  {
    name: "card-id-confirm",
    path: "/card/:id()/confirm",
    meta: confirmjCGpsthSEoMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/card/[id]/confirm.vue")
  },
  {
    name: "card-id-edit-card-alias",
    path: "/card/:id()/edit-card-alias",
    meta: edit_45card_45alias97fu2CCZPtMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/card/[id]/edit-card-alias.vue")
  },
  {
    name: "card-id-edit",
    path: "/card/:id()/edit",
    meta: editp3IWYcHfpBMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/card/[id]/edit.vue")
  },
  {
    name: "card-id",
    path: "/card/:id()",
    component: () => import("/codebuild/output/src3588095475/src/src/pages/card/[id]/index.vue")
  },
  {
    name: "card-create-confirm",
    path: "/card/create/confirm",
    meta: confirmYRSkf6VwudMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/card/create/confirm.vue")
  },
  {
    name: "card-create",
    path: "/card/create",
    meta: indexdEbjCs1Dc9Meta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/card/create/index.vue")
  },
  {
    name: "card",
    path: "/card",
    component: () => import("/codebuild/output/src3588095475/src/src/pages/card/index.vue")
  },
  {
    name: "contact-form-complete",
    path: "/contact-form/complete",
    meta: completeopVD1o9SWRMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/contact-form/complete.vue")
  },
  {
    name: "contact-form-confirm",
    path: "/contact-form/confirm",
    meta: confirmJYktaKHr8cMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/contact-form/confirm.vue")
  },
  {
    name: "contact-form-register",
    path: "/contact-form/register",
    meta: registeri5rKo23o6rMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/contact-form/register.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/codebuild/output/src3588095475/src/src/pages/contact.vue")
  },
  {
    name: "coupon-id",
    path: "/coupon/:id()",
    component: () => import("/codebuild/output/src3588095475/src/src/pages/coupon/[id].vue")
  },
  {
    name: "coupon",
    path: "/coupon",
    meta: index9SRFThsXsrMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/coupon/index.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/codebuild/output/src3588095475/src/src/pages/faq.vue")
  },
  {
    name: "history-debug",
    path: "/history-debug",
    component: () => import("/codebuild/output/src3588095475/src/src/pages/history-debug.vue")
  },
  {
    name: "history",
    path: "/history",
    meta: indexiAYDJ5O4ceMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/history/index.vue")
  },
  {
    name: "history-receipt-id",
    path: "/history/receipt/:id()",
    component: () => import("/codebuild/output/src3588095475/src/src/pages/history/receipt/[id].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexJLdkrhRx8oMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    component: () => import("/codebuild/output/src3588095475/src/src/pages/maintenance.vue")
  },
  {
    name: "membership-complete",
    path: "/membership/complete",
    meta: complete95xDnkB1mDMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/membership/complete.vue")
  },
  {
    name: "membership-confirm",
    path: "/membership/confirm",
    meta: confirmgYOj13HcdlMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/membership/confirm.vue")
  },
  {
    name: "membership-register",
    path: "/membership/register",
    meta: registervkIkyw7kuJMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/membership/register.vue")
  },
  {
    name: "membership-yumenavi-confirm-code",
    path: "/membership/yumenavi/confirm-code",
    meta: confirm_45codeFpLEOhPdSWMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/membership/yumenavi/confirm-code.vue")
  },
  {
    name: "membership-yumenavi-confirm",
    path: "/membership/yumenavi/confirm",
    meta: confirmQm874ufLKYMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/membership/yumenavi/confirm.vue")
  },
  {
    name: "membership-yumenavi-register",
    path: "/membership/yumenavi/register",
    meta: register0kS4hUn4huMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/membership/yumenavi/register.vue")
  },
  {
    name: "menu",
    path: "/menu",
    meta: menu3OdcxCmC4xMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/menu.vue")
  },
  {
    name: "mypage",
    path: "/mypage",
    meta: indexs9U97uifbFMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/mypage/index.vue")
  },
  {
    name: "mypage-integration",
    path: "/mypage/integration",
    meta: index2ZcpQfu08OMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/mypage/integration/index.vue")
  },
  {
    name: "mypage-integration-sample",
    path: "/mypage/integration/sample",
    meta: samplebWsqSPhKXpMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/mypage/integration/sample.vue")
  },
  {
    name: "mypage-membership-email-edit-complete",
    path: "/mypage/membership/email-edit/complete",
    component: () => import("/codebuild/output/src3588095475/src/src/pages/mypage/membership/email-edit/complete.vue")
  },
  {
    name: "mypage-membership-email-edit-confirm-code",
    path: "/mypage/membership/email-edit/confirm-code",
    component: () => import("/codebuild/output/src3588095475/src/src/pages/mypage/membership/email-edit/confirm-code.vue")
  },
  {
    name: "mypage-membership-email-edit",
    path: "/mypage/membership/email-edit",
    component: () => import("/codebuild/output/src3588095475/src/src/pages/mypage/membership/email-edit/index.vue")
  },
  {
    name: "mypage-membership",
    path: "/mypage/membership",
    component: () => import("/codebuild/output/src3588095475/src/src/pages/mypage/membership/index.vue")
  },
  {
    name: "mypage-membership-others-edit-complete",
    path: "/mypage/membership/others-edit/complete",
    component: () => import("/codebuild/output/src3588095475/src/src/pages/mypage/membership/others-edit/complete.vue")
  },
  {
    name: "mypage-membership-others-edit-confirm",
    path: "/mypage/membership/others-edit/confirm",
    component: () => import("/codebuild/output/src3588095475/src/src/pages/mypage/membership/others-edit/confirm.vue")
  },
  {
    name: "mypage-membership-others-edit",
    path: "/mypage/membership/others-edit",
    component: () => import("/codebuild/output/src3588095475/src/src/pages/mypage/membership/others-edit/index.vue")
  },
  {
    name: "mypage-membership-others-edit-update",
    path: "/mypage/membership/others-edit/update",
    component: () => import("/codebuild/output/src3588095475/src/src/pages/mypage/membership/others-edit/update.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi-confirm",
    path: "/mypage/membership/others-edit/yumenavi/confirm",
    component: () => import("/codebuild/output/src3588095475/src/src/pages/mypage/membership/others-edit/yumenavi/confirm.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi",
    path: "/mypage/membership/others-edit/yumenavi",
    component: () => import("/codebuild/output/src3588095475/src/src/pages/mypage/membership/others-edit/yumenavi/index.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi-update",
    path: "/mypage/membership/others-edit/yumenavi/update",
    component: () => import("/codebuild/output/src3588095475/src/src/pages/mypage/membership/others-edit/yumenavi/update.vue")
  },
  {
    name: "mypage-membership-password-edit-complete",
    path: "/mypage/membership/password-edit/complete",
    component: () => import("/codebuild/output/src3588095475/src/src/pages/mypage/membership/password-edit/complete.vue")
  },
  {
    name: "mypage-membership-password-edit-update",
    path: "/mypage/membership/password-edit/update",
    component: () => import("/codebuild/output/src3588095475/src/src/pages/mypage/membership/password-edit/update.vue")
  },
  {
    name: "mypage-pass",
    path: "/mypage/pass",
    meta: indexecT7kZQ6GeMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/mypage/pass/index.vue")
  },
  {
    name: "mypage-pass-receipt-id",
    path: "/mypage/pass/receipt/:id()",
    component: () => import("/codebuild/output/src3588095475/src/src/pages/mypage/pass/receipt/[id].vue")
  },
  {
    name: "mypage-sign-out-complete",
    path: "/mypage/sign-out/complete",
    meta: completenLcIQRHGSYMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/mypage/sign-out/complete.vue")
  },
  {
    name: "mypage-sign-out-confirm",
    path: "/mypage/sign-out/confirm",
    meta: confirm6PhLtn3y01Meta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/mypage/sign-out/confirm.vue")
  },
  {
    name: "mypage-unsubscribe-complete",
    path: "/mypage/unsubscribe/complete",
    meta: completeciYSKtHqQAMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/mypage/unsubscribe/complete.vue")
  },
  {
    name: "mypage-unsubscribe-confirm",
    path: "/mypage/unsubscribe/confirm",
    meta: confirmaZUSu1XYzFMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/mypage/unsubscribe/confirm.vue")
  },
  {
    name: "mypage-unsubscribe-consent",
    path: "/mypage/unsubscribe/consent",
    meta: consent0XveizfUtPMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/mypage/unsubscribe/consent.vue")
  },
  {
    name: "notice-id",
    path: "/notice/:id()",
    component: () => import("/codebuild/output/src3588095475/src/src/pages/notice/[id].vue")
  },
  {
    name: "notice",
    path: "/notice",
    meta: indexrWR3KM5e2aMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/notice/index.vue")
  },
  {
    name: "parking-id",
    path: "/parking/:id()",
    meta: _91id_93cNnXeCaP2MMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/parking/[id].vue")
  },
  {
    name: "pass-agreement",
    path: "/pass-agreement",
    component: () => import("/codebuild/output/src3588095475/src/src/pages/pass-agreement.vue")
  },
  {
    name: "reset-password-complete",
    path: "/reset-password/complete",
    meta: completezgfWSxx9ClMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/reset-password/complete.vue")
  },
  {
    name: "reset-password-confirm-code",
    path: "/reset-password/confirm-code",
    meta: confirm_45codeWOO5rMUWNiMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/reset-password/confirm-code.vue")
  },
  {
    name: "reset-password-new-password",
    path: "/reset-password/new-password",
    meta: new_45passwordIOqhod0wqKMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/reset-password/new-password.vue")
  },
  {
    name: "reset-password-send-email",
    path: "/reset-password/send-email",
    meta: send_45emailVO40EMhWzvMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/reset-password/send-email.vue")
  },
  {
    name: "sign-in-confirm-code",
    path: "/sign-in/confirm-code",
    meta: confirm_45codeEtBWUaRSGoMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/sign-in/confirm-code.vue")
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: index91Z9AbJVgdMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/sign-in/index.vue")
  },
  {
    name: "sign-up-confirm-code",
    path: "/sign-up/confirm-code",
    meta: confirm_45codeJV8M8CjbaEMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/sign-up/confirm-code.vue")
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: indexdex1APwl2qMeta || {},
    component: () => import("/codebuild/output/src3588095475/src/src/pages/sign-up/index.vue")
  },
  {
    name: "tutorial",
    path: "/tutorial",
    component: () => import("/codebuild/output/src3588095475/src/src/pages/tutorial.vue")
  }
]